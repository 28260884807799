.container_report {
  overflow-x: auto;
  margin-top: 50px;
  color: "#000";
}

.table_reports {
  width: 100%;
  border: 1px solid #ddd;
}

.table_reports,
.t_head,
.t_data {
  border: 1px solid black;
  border-collapse: collapse;
  margin-top: 10px;
}

.t_head,
.t_row,
.t_data {
  padding: 2px 3px;
  min-width: 80px;
}

.table_footer {
  margin-top: 10px;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

tfoot {
  font-weight: bold;
}

.line {
  width: 100%;
  border-top: 1px solid #444;
}

.header_report {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title_report {
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 10px;
}

.subtitle_report {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.time_container {
  margin-top: 6px;
  margin-bottom: -6px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.info_container div {
  flex: 1;
}

.item_info_title {
  display: flex;
  line-height: 10px;
}

.item_info_title p {
  width: 100px;
  font-weight: bold;
}

.button_print {
  right: 10;
  margin-right: 20;
  top: 20;
  margin-top: -30;
}
